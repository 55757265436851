import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

export default function SinglePageS({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <section className="app-content">
        <div
          className="main-banner"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `
              url("${
                contentDataData[0].children[0] &&
                contentDataData[0].children[0].value
                  ? contentDataData[0].children[0].value
                  : "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/6d2f19dc-e06c-4883-b522-a2f90a837ff8/D7v9tMfrTw6Iq8qvYASr_derbyshire_estate_main.jpg"
              }")`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="VF_filter_form">
                <div className="searchBlock">
                  <h1>{contentDataData[0].children[1].value}</h1>
                  <div className="main-intro">
                    <p>
                      <a
                        href={contentDataData[0].children[2].value}
                        className="button btn btn-primary-inverted btn-small"
                      >
                        {contentDataData[0].children[3].value}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SmartFeedFiltersComponent
          setVacancyLoading={setVacancyLoading}
          vacancyLoading={vacancyLoading}
          apiKey={"KveXyBB8IM6CDLBqJxwd16Qd6u7LWisyQeg1buu3SrQ"}
          region={contentDataData[0].children[4].value}
          category={
            contentDataData[0].children[5]
              ? contentDataData[0].children[5].value
              : null
          }
          group={false}
          gdprKey=""
          CompanyGroupName="Devonshire Group"
          VacancyPageBaseURL="https://devonshiregroupcareers.co.uk/vacancies/vacancy/"
          ReturnToSearchURL="https://devonshiregroupcareers.co.uk/vacancies/"
          contentDataData={contentDataData}
        />

        <div className="top">
          <div className="container" style={{ margin: "0px auto" }}>
            <div id="job-alerts" className="row alerts">
              <div className="col-md-12 col-12">
                <div className="job-alert-wrap">
                  <span>Keep up to date with the latest jobs from us</span>
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="button-wrap">
                  <Link
                    to="/job-alerts"
                    type="button"
                    className="btn btn-primary btn-jobalerts"
                  >
                    SUBSCRIBE TO JOB ALERTS{"   "}
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
